<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nom</th>
                    <th scope="col">Date</th>
                    <th scope="col">Formation</th>
                    <th scope="col">Document</th>
                </tr>
            </thead>

            <tbody>
                <template v-for="f in formations">
                    <template v-if="f.first">
                        <tr :key="f.id">
                            <td style="background-color: #1D1D5B; color: white;"><strong>{{ f.prenom }} {{ f.nom
                            }}</strong></td>
                            <td>{{ f.dt }}</td>
                            <td>{{ f.formation }}</td>
                            <td><a :href="doc(f.pdf)" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>
                        </tr>
                    </template>
                    <template v-if="!f.first">
                        <tr :key="f.id">
                            <td>&nbsp;</td>
                            <td>{{ f.dt }}</td>
                            <td>{{ f.formation }}</td>
                            <td><a :href="doc(f.pdf)" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>

        <br />
        <br />
        <br />
        <br />
    </div>
</template>

<script>
import store from "../store";
import { getPrefix, executeApi, selectApi } from "../lib/api.js";
import Vue from "vue";
import moment from "moment";

export default {
    name: "FormationCGB",
    store: store,
    data() {
        return {
            countformation: 0,
            formations: []
        };
    },
    computed: {
    },
    methods: {
        doc(code) {
            let ret = getPrefix() + "/formation/" + code;
            return ret;
        },
        refresh() {
            let sql =
                "SELECT f.dateformation,f.pdf,u.id as iduser,u.nom,u.prenom,t.libelle AS nomformation FROM formationaero AS f" +
                " LEFT JOIN cgbcommun.user AS u ON u.id=f.iduser" +
                " LEFT JOIN cgbcommun.tableref AS t ON t.id=f.idformation ORDER BY u.id ASC,t.libelle ASC";

            selectApi(this, sql).then(
                (response) => {
                    this.countformation = 0
                    this.formations = []

                    let lastuser = 0
                    response.data.result.forEach(item => {

                        const p = {
                            id: this.countformation,
                            first: lastuser != item.iduser,
                            nom: item.nom,
                            prenom: item.prenom,
                            pdf: item.pdf,
                            dt: moment(item.dateformation).format("DD/MM/YYYY"),
                            formation: item.nomformation
                        }

                        this.formations.push(p)

                        lastuser = item.iduser
                        this.countformation++

                    }
                    );
                },
                (response) => {
                }
            )
        }
    },
    mounted() {
        if (store.state.typeUser == 0) {
            this.$router.push({ name: "login" });
        }
        this.refresh();
    },
};
</script>

<style lang="scss"></style>
